// == PWD WP Sass Framework ==
// Hero element styles
// Template: templates/blocks/button.php

@import "common/variables";
@import "common/tools";

.icon-button {
	display: inline-flex;
	background-color: $theme-color-3;
	color: $white;
	font-family: $font-family-2;
	font-weight: bold;
	@include pwd-fontsize(18);
	margin: 0 0 10px 0;
	border-radius: 4px;
	padding: 15px 15px;
	text-decoration: none;
	align-items: center;

	&.alignwide {
		width: 100%;
	}

	.icon {
		background-repeat: no-repeat;
		background-size: contain;
		width: 30px;
		height: 24px;
		margin-right: 8px;
		background-image: url(#{$base-image-dir}icon-at.svg);
	}

	.icon-type-0 {
		background-image: url(#{$base-image-dir}icon-at.svg);
	}

	.icon-type-1 {
		background-image: url(#{$base-image-dir}icon-phone-01.svg);
	}

	.icon-type-2 {
		background-image: url(#{$base-image-dir}icon-hospital.svg);
	}

	.icon-type-3 {
		background-image: url(#{$base-image-dir}icon-arrow-right.svg);
	}

	.icon-type-4 {
		background-image: url(#{$base-image-dir}icon-form.svg);
	}

	.icon-type-5 {
		background-image: url(#{$base-image-dir}icon-cutlery.svg);
	}

	.icon-type-6 {
		background-image: url(#{$base-image-dir}icon-calendar.svg);
	}

	.icon-type-7 {
		background-image: url(#{$base-image-dir}icon-globe-white.svg);
	}

	&:hover,
	&:focus,
	&:active {
		background-color: darken($theme-color-3, 10%);
		color: $white !important;
	}

	aside & {
		width: 100%;
	}
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
}

@media screen and (min-width: map-get($container-max-widths, xl)) {
}
